// #region Utils
function vw(argVW) { return $(window).width() * 0.01 * (argVW || 100); }
function vh(argVH) { return $(window).height() * 0.01 * (argVH || 100); }
// #endregion Utils

window.cadent = {};
cadent.initialised = new Date();
(function ($) {

    cadent.utils = {
        loadScript: function (arg_url, arg_callback, arg_target) {
            var el_target = $('head').eq(0);
            if (arg_target) {
                if ($(arg_target).length > 0) {
                    el_target = $(arg_target).eq(0);
                }
            }

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = arg_url;

            if (typeof arg_callback === 'function') {
                script.onreadystatechange = arg_callback;
                script.onload = arg_callback;
            }

            el_target[0].appendChild(script);
        }
    };

    cadent.window = {
        scrolling: false,
        scrollTo: function (arg_target, arg_offset, arg_duration) {
            var num_duration = (typeof arg_duration === 'number') ? arg_duration : 800;
            if($(arg_target).length>0) {
                var el_target = $(arg_target).eq(0);

                var num_scrollPos = el_target.offset().top;

                var windowHeight = $(window).height();
                if(windowHeight > el_target.outerHeight()) {
                    num_scrollPos -= (0.5 * (windowHeight - el_target.outerHeight()));
                }

                if (el_target.is('.homepage-section')) {
                    if (typeof arg_offset !== 'number') {
                        arg_offset = (0 - vh(15));
                    }
                }

                if (typeof arg_offset === 'number') {
                    num_scrollPos += arg_offset;
                }

                var num_scrollPos_cur = $(document).scrollTop();

                if (num_scrollPos != num_scrollPos_cur) {
                    cadent.window.scrolling=true;
                    $('html,body')
                        //.stop(true,true)
                        .animate(
                            {'scrollTop': num_scrollPos},
                            num_duration,
                            function(){cadent.window.scrolling=false;}
                        );
                }
            }
        },
        events: {
            scroll: function () {
                cadent.sections.check();
                // cadent.section.goals.scroll();
                cadent.section.caseStudies.check();
            },
            resize: function () {
                cadent.sections.check();
                cadent.section.goals.resize();
                cadent.section.caseStudies.check();

                $('main.site-main').css('min-height', 'calc(100vh - ' + $('footer.site-foot').outerHeight() + 'px)');

                document.querySelector(':root').style.setProperty('--cadent-viewport-height', $(window).innerHeight() + 'px');
            },
            ready: function () {
                cadent.window.events.resize();

                cadent.links.init();
                cadent.animations.init();

                cadent.sections.check();
                cadent.section.caseStudies.check();

                cadent.posts.init();

                cadent.calendly.init();

                var el_body = $('body');
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $('html').toggleClass('cadent-head-open', $('body').hasClass('site-head-open'));
                    });
                });

                observer.observe(el_body[0], {
                    attributes: true,
                    attributeFilter: ['class']
                });
            },
            load: function () {
                $('body').addClass('loaded');

                cadent.window.events.resize();

                // If page loaded with a target hash, snap to location and clear hash
                var str_hash = window.location.hash.replace(/^([\#\/]+)?/, '').replace(/\/$/,'').split('/')[0].replace(/[^a-zA-Z0-9_\-]/g,'');
                if (str_hash != '') {
                    cadent.links.scrollTo(str_hash, true);
                }

                cadent.loader.loaded();
                cadent.sections.check();

                cadent.section.caseStudies.check();

                cadent.portal.init();
            }
        },
        init: function () {
            $(window).scroll(function (e) {
                cadent.window.events.scroll();
            });
            $(window).resize(function (e) {
                cadent.window.events.resize();
            });

            $(window).on("load", function (e) {
                cadent.window.events.load();
            });

            $(function () {
                cadent.window.events.ready();
            });
        }
    };
    cadent.window.init();

    // Show the loader until:
    // - At least cadent.loader.min ms have elapsed since page init, AND
    // - window.load() has fired
    cadent.loader = {
        min: 2000,
        loaded: function () {
            var date_loaded = new Date();
            var int_ms_loaded = (date_loaded.getTime() - cadent.initialised.getTime());
            var int_ms_delay = Math.max(0, (cadent.loader.min - int_ms_loaded));

            window.setTimeout(function () {
                $('body > .loader').addClass('loaded');
            }, int_ms_delay);
        }
    };

    cadent.links = {
        scrollTo: function (arg_hash, arg_clear, arg_event) {
            if (typeof arg_hash === 'string') {
                var str_hash = arg_hash.replace(/^([\#\/]+)?/, '').replace(/\/$/, '').split('/')[0].replace(/[^a-zA-Z0-9_\-]/g, '');
                if (str_hash != '') {
                    var el_target = $('#' + str_hash);
                    if (el_target.length > 0) {
                        if (typeof arg_event !== 'undefined') {
                            arg_event.preventDefault();
                        }
                        if ($('body').hasClass('site-head-open')) {
                            $('body').removeClass('site-head-open');
                            window.setTimeout(function () {
                                cadent.window.scrollTo(el_target.eq(0));
                            }, 400);
                        } else {
                            cadent.window.scrollTo(el_target.eq(0));
                        }

                        if (arg_clear === true) {
                            window.location.hash = '/';
                        }
                    }
                }
            }
        },
        init: function () {
            // Add listener for clicks on hash links
            $('body').on('click', 'a[href*="#"]', function (e) {
                var str_href = $(this).attr('href');

                if (str_href === '#') {
                    e.preventDefault();
                    return false;
                }

                var url = new URL(str_href, window.location.origin + window.location.pathname);

                if (window.location.pathname == url.pathname) {
                    cadent.links.scrollTo(url.hash, false, e);
                }
            });

            // Reset menu scroll position for mobile devices before opening menu
            $('body').on('click', '.nav-burger', function (e) {
                if ($('body').hasClass('site-head-open')) {
                    $('.site-head-container').scrollTop(0);
                }
            });
        }
    };

    cadent.animations = {
        init: function () {
            $('.site-head-menu .nav-primary a').addClass('animated-letters');

            $('.animated-letters').each(function () {
                var str_text = $(this).text();
                var arr_c = str_text.split('');

                // $(this).html('<span>' + str_text.split('').join('</span><span>') + '</span>');
                // $(this).find('span').each(function () {
                //     $(this).attr('data-c', $(this).text());
                // });

                var str_html = '<span class="word">';
                for (var i = 0; i < arr_c.length; i++) {
                    var str_c = arr_c[i];
                    if (str_c == ' ') {
                        str_html += '</span> <span class="word">';
                    }
                    else {
                        str_html += '<span data-c="' + str_c + '" style="--i:' + i + '">' + str_c + '</span>';
                    }
                }
                str_html += '</span>';

                $(this).html(str_html);
            });
        }
    };

    cadent.sections = {
        current: '',
        threshold: 75,
        check: function () {
            var arr_sections = $('.home-template .sections > section');
            if (arr_sections.length > 0) {

                var str_id_cur = cadent.sections.current;

                arr_sections.each(function (index, element) {

                    var el_s = $(this);
                    var el_s_pos_top = el_s[0].getBoundingClientRect().top;
                    var el_s_pos_btm = el_s[0].getBoundingClientRect().bottom;

                    var num_threshold = cadent.sections.threshold;

                    if ((el_s_pos_top <= vh(num_threshold)) && (el_s_pos_btm >= vh(num_threshold))) {
                        str_id_cur = el_s.attr('id') || index;
                    }
                });

                if (str_id_cur !== cadent.sections.current) {
                    cadent.sections.current = str_id_cur;
                    $('body').attr('data-section', str_id_cur);

                    arr_sections.each(function () {
                        var el_s = $(this);
                        el_s.toggleClass('active', (el_s.attr('id') == cadent.sections.current));
                    });
                }
            }
        }
    };

    cadent.section = {
        goals: {
            resizeImage: function (arg_target) {
                if (
                    (!$(arg_target).is('#goals .goals .goal .goal-image'))
                    || (typeof $(arg_target)[0].style.backgroundImage !== 'string')
                    || ($(arg_target)[0].style.backgroundImage.indexOf('url(') < 0)
                ) {
                    return;
                }

                var el_img = $(arg_target);

                if (typeof el_img.data('ratio') !== 'number') {
                    var str_imgUrl = el_img[0].style.backgroundImage.match(/^url\((['"])?([^'"]+)(['"])?\)$/i)[2];
                    const img = new Image();
                    img.targetElement = el_img;
                    img.onload = function () {
                        $(this.targetElement).data('ratio', (this.width / this.height));
                        cadent.section.goals.resizeImage($(this.targetElement));
                    }
                    img.src = str_imgUrl;
                    return;
                }

                var num_wt_el = el_img.outerWidth();
                var num_ht_el = el_img.outerHeight();

                var num_ratio_img = el_img.data('ratio');
                var num_ratio_el = num_wt_el / num_ht_el;

                var str_size = null;
                var str_pos = null;

                // Image taller than container
                if (num_ratio_img <= num_ratio_el) {
                    str_size = num_wt_el + 'px auto';

                    var num_ht_img = num_wt_el / num_ratio_img;
                    str_pos = '0px ' + (0.5 * (num_ht_el - num_ht_img)) + 'px';
                }
                // Image wider than container
                else {
                    str_size = 'auto ' + el_img.height() + 'px';

                    var num_wt_img = num_wt_el * num_ratio_img;
                    str_pos = (0.5 * (num_wt_img - num_wt_img)) + 'px 0px';
                }

                el_img.css({
                    'background-position': str_pos,
                    'background-size': str_size
                });
            },
            resize: function () {
                var arr_goal_images = $('#goals .goals .goal .goal-image');
                if (arr_goal_images.length) {
                    arr_goal_images.each(function(){
                        cadent.section.goals.resizeImage($(this));
                    });
                }
            },
            scroll: function () {
                // var arr_goal_images = $('#goals .goals .goal .goal-image');
                // if (arr_goal_images.length) {
                //     arr_goal_images.each(function(){
                //         $(this).css('background-position','center ' + ($(this)[0].getBoundingClientRect().top * -1) + 'px')
                //     });
                // }
            }
        },
        caseStudies: {
            buffer: 12.5,
            check: function () {
                var el_caseStudies = $('.home-template .sections > section#case-studies .case-studies');
                if (el_caseStudies.length == 1) {
                    var el_caseStudies_inner = el_caseStudies.children('.inner').eq(0);

                    var int_caseStudies = el_caseStudies.find('.case-study').length;

                    var int_scrollPos = $(document).scrollTop();
                    var int_scrollRange_start = el_caseStudies.offset().top;
                    var int_scrollRange_end = int_scrollRange_start + el_caseStudies.height() - vh(100);

                    var num_transformPercent = 0;

                    var str_scrollRange_position = '';
                    if ((int_scrollPos >= int_scrollRange_start) && (int_scrollPos <= int_scrollRange_end)) {
                        str_scrollRange_position = 'in';

                        var num_scrollPercent = ((int_scrollPos - int_scrollRange_start) / (int_scrollRange_end - int_scrollRange_start)) * 100;

                        var num_buffer = cadent.section.caseStudies.buffer;
                        var num_scrollPercent_buffered = Math.max(0, Math.min(100, (((0.1 * num_buffer) * num_scrollPercent) - num_buffer)));

                        num_transformPercent = (num_scrollPercent_buffered * (int_caseStudies - 1));

                    }
                    else {
                        if (int_scrollPos < int_scrollRange_start) {
                            str_scrollRange_position = 'before';
                        }
                        else {
                            str_scrollRange_position = 'after';
                            num_transformPercent = (100 * (int_caseStudies - 1));
                        }
                    }

                    var num_transformPercent_current = 0;
                    var str_css = el_caseStudies_inner.attr('style');
                    if (/translateX\(\-?([\d\.]+)\%\)/i.test(str_css)) {
                        num_transformPercent_current = Math.abs(parseFloat(str_css.split('translateX(')[1].split('%')[0]));
                    }

                    if (num_transformPercent_current != num_transformPercent) {
                        el_caseStudies_inner.css('transform', 'translateX(-' + num_transformPercent + '%)')
                    }

                    // Set attributes

                    if (el_caseStudies.attr('data-case-studies') != int_caseStudies) {
                        el_caseStudies.attr('data-case-studies', int_caseStudies)
                    }

                    if (el_caseStudies.attr('data-scroll') != str_scrollRange_position) {
                        el_caseStudies.attr('data-scroll', str_scrollRange_position);
                    }

                    var str_ht = el_caseStudies_inner.height() + 'px';
                    if (el_caseStudies.css('height') != str_ht) {
                        el_caseStudies.css('height', str_ht);
                    }
                }
            }
        }
    };

    cadent.posts = {
        placeholders: 6,
        init: function () {
            if ($('.feed-posts .feed-post').length > 0) {
                $('.feed-posts .feed-post.no-image').each(function (index, element) {
                    var int_placeholder = index + 1;
                    while (int_placeholder > cadent.posts.placeholders) {
                        int_placeholder -= cadent.posts.placeholders;
                    }
                    $(this).find('.feed-post-image').attr('data-placeholder', int_placeholder);
                });
            }
        }
    };

    cadent.portal = {
        timer: null,
        content: {
            header: {
                signup: null,
                signin: null,
            },
            set: function (arg_target, arg_name) {
                var obj_content = cadent.portal.content.header[arg_name];
                if ($(arg_target).is('.gh-portal-popup-wrapper')) {
                    var el_target = $(arg_target);

                    if (el_target.find('.gh-portal-content > header > .gh-portal-header-content').length == 0) {
                        el_target.find('.gh-portal-content > header').append('<div class="gh-portal-header-content"></div>');
                    }

                    if (
                        (typeof obj_content === 'object')
                        && (obj_content !== null)
                    ) {
                        el_target.find('.gh-portal-main-title').html(obj_content.title);
                        el_target.find('.gh-portal-content > header > .gh-portal-header-content').html(obj_content.html);
                    }

                    el_target.addClass('ready');
                    $('#ghost-portal-root iframe').addClass('ready');
                }
            },
            update: function (arg_target) {
                if ($(arg_target).is('.gh-portal-popup-wrapper')) {
                    var el_target = $(arg_target);

                    var bool_customContent = false;
                    Object.keys(cadent.portal.content.header).forEach(function(key) {
                        if (el_target.hasClass(key)) {
                            cadent.portal.content.set(arg_target, key);
                            bool_customContent = true;
                        }
                    });

                    if (!bool_customContent) {
                        el_target.addClass('ready');
                        $('#ghost-portal-root iframe').addClass('ready');
                    }
                }
            },
            fetch: function () {
                const api = new GhostContentAPI({
                    url: 'https://cadent.foxandballoon.com',
                    key: '869b82db4be0030781631c62e4',
                    version: "v5.0"
                });

                api.posts
                    .read({ slug: 'portal-signup-header' })
                    .then(function (post) {
                        cadent.portal.content.header.signup = post;
                    })
                    .catch((e) => { });

                api.posts
                    .read({ slug: 'portal-signin-header' })
                    .then(function (post) {
                        cadent.portal.content.header.signin = post;
                    })
                    .catch((e) => { });
            }
        },
        open: function () {
            $('.cadent-portal-trigger')[0].click();
        },
        ready: function () {
            $('#ghost-portal-root iframe').contents().find('head').append('<link rel="stylesheet" type="text/css" href="' + window.location.origin + '/assets/built/portal.css" onload="cadent.window.loaded()">');
            cadent.utils.loadScript(
                window.location.origin + '/assets/built/portal.js',
                function () {
                    $('#ghost-portal-root iframe').contents().find('body').addClass('loaded-js');
                    $('#ghost-portal-root iframe').addClass('loaded-js');
                },
                $('#ghost-portal-root iframe').contents().find('head')
            );
        },
        init: function () {
            cadent.portal.content.fetch();

            var arr_selectors = [
                '[data-portal]',
                '[href$="#/portal"]',
                '[href$="#/portal/"]'
            ];
            var arr_triggers = $(arr_selectors.join(',')).not('.cadent-portal-trigger');

            arr_triggers.filter('a').attr('href', '#').attr('role', 'button');
            arr_triggers.off('click').unbind('click');

            arr_triggers.attr('data-cadent-portal', '');

            $('body').on('click', '[data-cadent-portal]', function (e) {
                e.preventDefault();
                e.stopPropagation();
                cadent.portal.open();
                return false;
            });

            $('#ghost-portal-root').on('DOMNodeInserted', function (e) {
                clearInterval(cadent.portal.timer);
                cadent.portal.timer = window.setInterval(function () {
                    if (
                        ($('#ghost-portal-root iframe').length > 0)
                        && ($('#ghost-portal-root iframe').contents())
                        && ($('#ghost-portal-root iframe').contents().find('head').length > 0)
                    ) {
                        clearInterval(cadent.portal.timer);
                        cadent.portal.ready();
                    }
                }, 100);
            });
        }
    };

    cadent.calendly = {
        init: function () {
            var arr_links = $('[onclick^="Calendly.initPopupWidget"]');
            if (arr_links.length > 0) {
                arr_links.each(function (index, element) {
                    var str_onclick = $(this).attr('onclick');
                    $(this).attr('onclick', str_onclick.replace(/color=\#/ig, 'color='));
                });
            }

            $('body').on('click', '[onclick^="Calendly.initPopupWidget"]', function () {
                cadent.window.events.resize();
            });
        }
    };

})(jQuery);
